<template>
  <section>

    <div>
      <div class="text-left">
        <span class="f16 font-weight-light">{{
            video.createDate | moment("from", "now")
          }}</span>
      </div>

      <v-col xs="12" sm="12" md="8" lg="6" xl="4" cols="12" class="mx-auto">
        <vue-plyr style="">
          <video
              :src="video.url"
              type="video/mp4"
              controls
              playsinline
              :data-poster="video.picture"
              data-plyr-config='{ "ratio": "1:1" }'>
          </video>
        </vue-plyr>
      </v-col>


      <section class="mt-3">
        <span class="f16 font-weight-bold">{{ video.title }}</span>
        <p class="f14" v-html="video.description"></p>
        <v-chip-group mandatory show-arrows active-class="primary--text">
          <v-chip v-for="cat in video.groups" :key="cat.id" small>
            {{ cat.value }}
          </v-chip>
        </v-chip-group>

        <v-form ref="form" v-model="valid" lazy-validation>
          <span class="f16 font-weight-bold">{{ $t("Submit  comment") }}</span>
          <v-textarea
              class="mt-3 f14 rounded"
              flat
              outlined
              auto-grow
              :label="$t('Please write your comment')"
              counter="500"
              :rules="commentRules"
              v-model="commentValue"
          ></v-textarea>
          <v-btn
              elevation="0"
              block
              color="info"
              class="mt-0 mb-3 rounded"
              @click="validateComment()"
              :disabled="!valid"
          >{{ $t("Submit") }}
          </v-btn
          >
        </v-form>

        <span
            class="f16 font-weight-bold d-block mt-6"
            v-if="comments != null && comments.length == 1"
        >{{ $t("comment") }}</span
        >
        <span
            class="f16 font-weight-bold d-block mt-6"
            v-else-if="comments != null && comments.length > 1"
        >
          {{ comments.length }} {{ $t("comments") }}
        </span>

        <v-card
            class="border my-3"
            v-for="item in comments"
            :key="item.id"
            elevation="0"
        >
          <v-card-title class="d-flex justify-space-between align-center">
            <div>
              <v-avatar size="36">
                <v-img :lazy-src="require('@/assets/images/profile-circle.png')" v-if="item.authorPicture"
                       :src="item.authorPicture"></v-img>
                <v-img v-else :src="require('@/assets/images/profile.jpg')"></v-img>
              </v-avatar>
              <span class="ms-2 me-0 f13">{{
                  item.authorName
                }}</span>
            </div>
            <div>
                <span class="f13">{{
                    item.createDate | moment("from", "now")
                  }}</span>
            </div>
          </v-card-title>

          <v-card-text>
            <p class="f14" v-html="item.comment"></p>
          </v-card-text>

          <v-divider class="mt-1 mb-0"></v-divider>

          <v-card-actions class="d-flex justify-content-end">

            <v-btn
                flat depressed small class="min-height-32 h-auto py-1" color="red"
                @click="deleteComment(item.id)"
                v-if="$store.state.mobileNumber == item.userMobile"
            >
              <div class="d-flex d-inline-flex align-items-center text-white">
                  <span class="mx-1 f12">{{
                      $t("Delete")
                    }}</span>
                <iconly name="delete" type="bold" class="wh-20"/>
              </div>
            </v-btn>

            <v-divider vertical class="mx-1"></v-divider>

            <v-btn
                flat depressed small class="min-height-32 h-auto py-1" color="primary"
                v-on:click="unLikeComment(item.id)"
                v-if="item.liked"
            >
              <div class="d-flex d-inline-flex align-items-center">
                  <span class="mx-1 f12">{{
                      item.likeNumber
                    }}</span>
                <iconly name="heart" type="bold" class="wh-20"/>
              </div>
            </v-btn>

            <v-btn
                flat outlined small class="min-height-32 h-auto py-1 border border-primary"
                v-on:click="likeComment(item.id)"
                v-else
            >
              <div class="d-flex d-inline-flex align-items-center primary--text">
                  <span class="mx-1 f12">{{
                      item.likeNumber
                    }}</span>
                <iconly name="heart" type="light" class="wh-20"/>
              </div>
            </v-btn>

            <v-divider vertical class="mx-1"></v-divider>

            <v-dialog v-model="reportDialog" max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    flat outlined small class="min-height-32 h-auto py-1 border border-accent"
                    v-bind="attrs"
                    v-on="on"
                    @click="reportValue = null"
                >
                  <iconly name="danger" type="light" class="wh-20 red--text"/>
                </v-btn>
              </template>

              <v-card class="text-center pa-4">
                  <span class="f16 font-weight-bold d-block">{{
                      $t("Report")
                    }}</span>
                <p class="f14 p-2 font-weight-light">
                  {{
                    $t(
                        "Please write the reason for registration or violation in the box below and register. Your report will be reviewed by our experts"
                    )
                  }}
                </p>
                <v-textarea
                    class="f14 rounded-lg"
                    flat
                    outlined
                    auto-grow
                    counter="1000"
                    v-model="reportValue"
                    :rules="reportRules"
                ></v-textarea>

                <v-btn
                    @click="reportValue && reportValue.length > 3 ? reportContent() : null"
                    color="info"
                    block
                    class="mb-2"
                >{{ $t("Submit") }}
                </v-btn
                >
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>

      </section>

      <v-footer
          fixed
          app
          tile
          color="transparent"
          padless
          class="mb-2"
      >
        <v-container>
          <v-card class="d-flex flew-row justify-space-between pa-2 border" elevation="1">
            <div class="d-flex align-center pointer text-decoration-none" @click="openDrBottomSheet(video.publisherId)">
              <div>
                <v-avatar size="36">
                  <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="video.publisherPic"></v-img>
                </v-avatar>
              </div>
              <div class="ms-2 me-0 f14">
                <span>{{ video.publisherName }}</span>
              </div>
            </div>
            <div class="text-left d-flex align-items-center">

              <v-btn flat outlined small class="min-height-32 py-1 border border-primary">
                <div class="d-flex align-items-center justify-content-center primary--text">
                  <span class="me-1 ms-0 f14">{{
                      video.viewCount
                    }}</span>
                  <iconly type="bold" name="show" class="wh-20"></iconly>
                </div>
              </v-btn>

              <v-spacer class="mx-1"></v-spacer>

              <v-btn
                  flat depressed small class="min-height-32 py-1" color="primary"
                  @click="unLike()"
                  v-if="video.liked"
              >
                <div class="d-flex d-inline-flex align-items-center">
                  <span class="me-1 ms-0 f14">{{
                      video.likeNumber
                    }}</span>
                  <iconly name="heart" type="bold" class="wh-20"/>
                </div>
              </v-btn>

              <v-btn
                  flat outlined small class="min-height-32 py-1 border border-primary"
                  @click="like()"
                  v-else
              >
                <div class="d-flex align-items-center justify-content-center primary--text">
                  <span class="me-1 ms-0 f14">{{
                      video.likeNumber
                    }}</span>
                  <iconly name="heart" type="light" class="wh-20"/>
                </div>
              </v-btn>

              <v-spacer class="mx-1"></v-spacer>

              <v-btn
                  flat depressed small class="min-height-32 py-1" color="primary"
                  @click="deleteBookmark()"
                  v-if="video.bookmarked"
              >
                <div class="d-flex d-inline-flex align-items-center">
                  <iconly name="bookmark" type="bold" class="wh-20"/>
                </div>
              </v-btn>

              <v-btn
                  flat outlined small class="min-height-32 py-1 border border-primary"
                  @click="bookmark()"
                  v-else
              >
                <div class="d-flex align-items-center justify-content-center primary--text">
                  <iconly name="bookmark" type="light" class="wh-20"/>
                </div>
              </v-btn>

            </div>
          </v-card>
        </v-container>
      </v-footer>
    </div>
    <BshDoctor ref="drBottomSheet" :fromDoctorApp="true"/>
  </section>
</template>

<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import ApiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.locale("fa", fa);
moment.loadPersian();
export default {
  components: {
    BshDoctor
  },
  data() {
    return {
      valid: false,

      video: {},
      commentValue: "",
      comments: [],
      reportValue: "",
      reportDialog: false,

      commentRules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters"),
        v => (v && v.length > 3) || this.$t("The minimum length is 4 characters")
      ],

      reportRules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters"),
        v => (v && v.length > 3) || this.$t("The minimum length is 4 characters")
      ],
    };
  },
  methods: {
    openDrBottomSheet(id) {
      this.$refs.drBottomSheet.openWithDoctorId(id);
    },
    getVideoById() {
      ApiService.getVideoById(this.$route.params.id)
          .then((response) => {
            this.video = response.data;

            if (this.video.publisher == this.$store.getters.mobileNumber) {
              var menu = [
                {
                  title: "Edit",
                  to: "/s/contents/video/" + this.video.id + "/edit",
                  click: '',
                },
                {
                  title: "Delete",
                  to: '',
                  click: this.deleteContent,
                },
              ];

              this.$store.commit('update_threeDotsActive', true);
              this.$store.commit('update_threeDotsMenu', menu);
              this.$store.commit('update_notificationActive', false);
            }
          })
          .catch(() => {
            this.$router.go(-1);
          });
    },

    deleteContent() {
      this.$swal({
        icon: "warning",
        text: this.$t('Are you sure you want to delete this content?'),
        confirmButtonText: this.$t('Yes'),
        showDenyButton: true,
        denyButtonText: this.$t('No'),
      })
          .then((result) => {
            if (result.isConfirmed) {
              this.submitDeleteContent();
            }
          });
    },
    submitDeleteContent() {
      ApiService.deleteVideo(this.video.id)
          .then(() => {
            this.$swal({
              icon: "success",
              text: this.$t('Your content was delete successfully'),
              confirmButtonText: this.$t('OK'),
            })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(-1);
                  }
                });
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            })
          });
    },

    getVideoCommentsById() {
      ApiService.getVideoCommentsById(this.$route.params.id).then(
          (response) => {
            this.comments = response.data;
          }
      );
    },
    validateComment() {
      if (this.$refs.form.validate()) {
        this.AddComment();
      }
    },
    AddComment() {
      const Data = {
        videoId: this.$route.params.id,
        comment: this.commentValue,
        userMobile: this.$store.state.mobileNumber
      };
      ApiService.addVideoComment(Data)
          .then((response) => {
            if (response.status == 200) {
              this.getVideoCommentsById();
              this.$toast.success(this.$t("Thank you! Your comment has been successfully submitted"), {rtl: this.$vuetify.rtl});
              this.commentValue = null;
            }
          })
          .catch(() => {
            setTimeout(() => {
              this.$toast.error(this.$t("Error receiving information"), {rtl: this.$vuetify.rtl});
            }, 100);
          });
    },
    setView() {
      ApiService.setViewVideo(this.$route.params.id).then();
    },
    like() {
      ApiService.likeVideo(this.$route.params.id).then(
          (response) => {
            if (response.status == 200) {
              this.getVideoById();
            }
          }
      );
    },
    unLike() {
      ApiService.unLikeVideo(this.$route.params.id).then(
          (response) => {
            if (response.status == 200) {
              this.getVideoById();
            }
          }
      );
    },
    bookmark() {
      const Data = {
        userMobile: this.$store.state.mobileNumber,
        videoId: this.$route.params.id,
      };
      ApiService.bookmarkvideo(Data).then((response) => {
        if (response.status == 200) {
          this.getVideoById();
        }
      });
    },
    deleteBookmark() {
      const Data = {
        userMobile: this.$store.state.mobileNumber,
        videoId: this.$route.params.id,
      };
      ApiService.deleteBokmarkvideo(Data).then((response) => {
        if (response.status == 200) {
          this.getVideoById();
        }
      });
    },
    likeComment(commentId) {
      ApiService.likeComment(
          commentId,
          "VIDEO",
          this.$route.params.id
      ).then((response) => {
        if (response.status == 200) {
          this.getVideoCommentsById();
        }
      });
    },
    unLikeComment(commentId) {
      ApiService.unLikeComment(
          commentId,
          "VIDEO",
          this.$route.params.id
      ).then((response) => {
        if (response.status == 200) {
          this.getVideoCommentsById();
        }
      });
    },
    reportContent() {
      ApiService.reportContent(
          this.$route.params.id,
          "COMMENT",
          this.reportValue
      ).then((response) => {
        if (response.status == 200 || 204) {
          this.reportDialog = false;
          this.$toast.success(this.$t("Report successfully submitted"), {rtl: this.$vuetify.rtl});
        }
      });
    },
    deleteComment(CommentId) {
      this.$swal({
        icon: "warning",
        text: this.$t('Are you sure you want to delete this item?'),
        confirmButtonText: this.$t('Yes'),
        showDenyButton: true,
        denyButtonText: this.$t('No'),
      })
          .then((result) => {
            if (result.isConfirmed) {
              ApiService
                  .deleteVideoComment(CommentId)
                  .then((response) => {
                    if (response.status == 200) {
                      this.$toast.success(this.$t("Comment successfully deleted"), {rtl: this.$vuetify.rtl});
                      this.getVideoCommentsById();
                    }
                  });
            }
          });


    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.getVideoById();
    this.getVideoCommentsById();
    this.setView();
  },
};
</script>